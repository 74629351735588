import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import media from 'styles/media'

import emailIcon from 'assets/icons/email-icon.svg'
import { Colors } from 'types/theme'
import { StaticImage } from 'gatsby-plugin-image'
import useBreakpoint from 'hooks/useBreakpoint'

import checkIcon from 'assets/icons/check.svg'
import errorIcon from 'assets/icons/error.svg'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { graphql, useStaticQuery } from 'gatsby'

const StyledWrapper = styled.div<{
  backgroundColor?: keyof Colors
  noMarginTop?: boolean
  withImage?: boolean
}>`
  padding-top: 29px;
  padding-bottom: 46px;
  width: 100%;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.lightgreen};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? '0' : '100px')};

  ${media.xl.min} {
    margin: ${({ withImage }) => (withImage ? '180px 0 0px' : '100px 0 0 0')};

    padding-top: 52px;
    padding-bottom: 60px;

    ${({ noMarginTop }) =>
      noMarginTop &&
      css`
        margin-top: 0;
      `}
  }
`

const StyledTitle = styled.h1`
  font-size: 23px;
  text-align: center;
  margin-bottom: 26px;
  z-index: 2;

  ${media.lg.min} {
    font-size: 36px;
  }
`

const StyledSubtitle = styled.p`
  margin-bottom: 40px;
  text-align: center;
  line-height: 2;
  z-index: 2;

  ${media.md.max} {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledInputWrapper = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  z-index: 2;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 37px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background-image: url('${emailIcon}');
  }

  ${media.sm.min} {
    width: 440px;
    margin: 0 auto;
  }
`

const StyledSubmitButton = styled.button`
  width: 62px;
  height: 62px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border: ${({ theme }) => `1px solid ${theme.colors.orange}`};

  svg {
    path {
      transition: 0.3s;
      fill: ${({ theme }) => theme.colors.orange};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.orange};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: lightgray;
      cursor: auto;

      &:hover {
        background-color: lightgray;

        svg {
          path {
            fill: ${({ theme }) => theme.colors.orange};
          }
        }
      }
    `}
`

const StyledInput = styled.input<{ error?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.orange};
  width: 100%;
  border-radius: 34px;
  height: 62px;
  padding-left: 70px;

  &::placeholder {
    font-size: 14px;
  }

  ${({ error }) =>
    error &&
    css`
      background-color: rgba(224, 56, 56, 0.17);
    `}
`

const StyledErrorMessageWrapper = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  animation: 0.3s fadein;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${media.sm.min} {
    width: 440px;
    margin: 0 auto;
  }
`

const StyledErrorMessage = styled.p`
  margin-top: 5px;
  text-align: center;
  color: #e03838;
  width: 100%;
  font-size: 14px;

  ${media.sm.min} {
    text-align: left;
    padding-left: 70px;
  }
`

const StyledFormMessage = styled.div`
  text-align: center;
  font-size: 14px;
`

const StyledHeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledText = styled.p`
  text-transform: uppercase;
  vertical-align: top;
  font-weight: 500;
`

const StyledDescription = styled.p`
  margin-top: 10px;
`

type NewsletterProps = {
  backgroundColor?: keyof Colors
  noMarginTop?: boolean
  withImage?: boolean
  womanImage?: boolean
  manImage?: boolean
  runningManImage?: boolean
}

const Newsletter: React.FC<NewsletterProps> = ({
  backgroundColor,
  noMarginTop,
  withImage,
  womanImage,
  manImage,
  runningManImage,
}) => {
  const newsletterData = useStaticQuery(graphql`
    query {
      wpPage(slug: { regex: "/strona-glowna/" }) {
        mainPage {
          newsletterSubtitle
          newsletterTitle
        }
      }
    }
  `)

  const { lg } = useBreakpoint()

  const [submitClicked, setSubmitClicked] = useState(false)

  const [formMessage, setFormMessage] = useState({
    text: '',
    description: '',
    icon: '',
  })

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Adres email jest niepoprawny')
      .required('Proszę o podanie adresu email'),
  })

  return (
    <StyledWrapper
      backgroundColor={backgroundColor}
      noMarginTop={noMarginTop}
      withImage={withImage}
    >
      {lg && womanImage && (
        <StaticImage
          src="../../assets/images/woman-image.png"
          alt="woman doing sports"
          style={{
            position: 'absolute',
            left: '15%',
            transform: 'scale(0.9)',
            zIndex: 1,
            transition: '.3s',
          }}
          className="woman-image"
        />
      )}
      {lg && manImage && (
        <StaticImage
          src="../../assets/images/standing-man-image.png"
          alt="man doing sports"
          style={{
            position: 'absolute',
            right: '5%',
            transform: 'scale(0.9)',
            zIndex: 1,
            transition: '.3s',
          }}
          className="man-image"
        />
      )}
      {lg && runningManImage && (
        <StaticImage
          src="../../assets/images/running-man-image.png"
          alt="man running"
          style={{
            position: 'absolute',
            right: '5%',
            transform: 'scale(0.7) translateY(5%)',
            zIndex: 1,
          }}
        />
      )}
      <StyledTitle>
        {newsletterData.wpPage.mainPage.newsletterTitle}
      </StyledTitle>
      <StyledSubtitle>
        {newsletterData.wpPage.mainPage.newsletterSubtitle}
      </StyledSubtitle>
      {formMessage.text ? (
        <StyledFormMessage>
          <StyledHeadingWrapper>
            <img
              src={
                formMessage.icon === 'success' ? `${checkIcon}` : `${errorIcon}`
              }
              alt=""
            />
            <StyledText>{formMessage.text}</StyledText>
          </StyledHeadingWrapper>
          <StyledDescription>{formMessage.description}</StyledDescription>
        </StyledFormMessage>
      ) : (
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log('here')

            const sendEmail = async () => {
              const { email } = values

              try {
                setSubmitting(true)

                const query = `
                mutation {
                  sendNewsletterForm(input: {email: "${email}"}) {
                    code
                    message
                    status
                    response {
                      field
                      message
                    }
                  }
                }

                `
                const { data } = await axios.post(
                  `https://cms.aurecactive.pl/?graphql=true`,
                  {
                    query,
                  }
                )

                setSubmitting(false)
                setFormMessage({
                  text: 'Dziękujemy za zgłoszenie.',
                  description:
                    'Będziemy Cię informować o nowościach Aurec Active.',
                  icon: 'success',
                })

                if (document.querySelector('.woman-image') !== null) {
                  document.querySelector('.woman-image')!.style.opacity = '0'
                }
                if (document.querySelector('.man-image') !== null) {
                  document.querySelector('.man-image')!.style.opacity = '0'
                }

                setTimeout(() => {
                  setFormMessage({ text: '', description: '', icon: '' })
                  resetForm()
                }, 10000)
              } catch (err) {
                setSubmitting(false)
                setFormMessage({
                  text: 'Wystąpił nieoczekiwany błąd',
                  description: 'Spróbuj ponownie później',
                  icon: 'error',
                })
                setTimeout(() => {
                  setFormMessage({ text: '', description: '', icon: '' })
                }, 2500)
              }
            }
            sendEmail()
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <StyledInputWrapper>
                <StyledInput
                  id="email"
                  placeholder="Wpisz email"
                  onChange={(e) => {
                    setSubmitClicked(false)
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  error={submitClicked && errors.email && touched.email}
                  value={values.email}
                  touched={touched.email}
                  errors={errors.email}
                />
                <StyledSubmitButton
                  aria-label="zapisz się do newslettera"
                  type="submit"
                  onClick={() => setSubmitClicked(true)}
                  disabled={isSubmitting}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.921"
                    height="14.404"
                    viewBox="0 0 7.921 14.404"
                  >
                    <path
                      id="arrow"
                      d="M249.263,6533.194a.764.764,0,0,0,1.054,0l6.166-5.951a1.405,1.405,0,0,0,0-2.037l-6.211-5.995a.765.765,0,0,0-1.047-.007.7.7,0,0,0-.008,1.026l5.685,5.485a.7.7,0,0,1,0,1.018l-5.639,5.443a.7.7,0,0,0,0,1.017"
                      transform="translate(-248.999 -6519.001)"
                      fill="#f4f9f3"
                      fill-rule="evenodd"
                    />
                  </svg>
                </StyledSubmitButton>
              </StyledInputWrapper>
              {submitClicked && errors.email && touched.email && errors.email && (
                <StyledErrorMessageWrapper>
                  <StyledErrorMessage>{errors.email}</StyledErrorMessage>
                </StyledErrorMessageWrapper>
              )}
            </Form>
          )}
        </Formik>
      )}
    </StyledWrapper>
  )
}

export default Newsletter
